<template>
  <div>
    <v-container style="padding: 0px;" class="mb-9" fluid>
      <div class="mb-5">
        <vendor-detail-searchbar-layout></vendor-detail-searchbar-layout>
      </div>
      <v-row v-if="loaderSkeleton">
        <v-col v-for="(item, index) in 6" :key="index" cols="12" md="4">
          <div>
            <v-sheet color="grey lighten-4" class="pa-0">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="12">
          <!-- content cards -->
          <div v-if="aArticles.length > 0">
            <v-row>
              <v-col v-for="(item, index) in aArticles" :key="index" cols="12" xl="2" lg="3" md="4" sm="6" xs="12">
                <div class="card-container">
                  <div class="content-image">
                    <img v-if="item.aImages.length > 0" :src="item.aImages[0].oImages.md" alt="" />
                    <img v-else src="@/assets/images/placeholder-image.jpeg" alt="" />
                  </div>
                  <div class="mt-5">
                    <p class="text-title mon-medium">{{ item.sName }}</p>
                    <p class="text-provider mon-bold">
                      <v-icon size="15px" color="#9AA6B1">
                        mdi-account
                      </v-icon>
                      {{ item.sVendorEnterpriseName }}
                    </p>
                    <p class="text-location mon-bold">
                      <v-icon size="15px" color="#9AA6B1">
                        mdi-map-marker
                      </v-icon>
                      {{ item.sLocationWarehouse }}
                    </p>
                    <div class="display-flex align-items-center mb-3">
                      <v-rating :value="item.dRating" length="5" color="#FFC92A" background-color="#C6C6C8" dense small readonly></v-rating>
                      <p :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}" class="text-calif-num mon-bold ml-1 mb-n1">
                        {{ item.dRating > 5 ? 5 : item.dRating }}
                      </p>
                      <!-- <v-icon
                        size="15px"
                        color="#FFC92A"
                      >
                        mdi-star
                      </v-icon>
                      <p class="text-number-stars mon-bold">4.5</p>
                      <p class="text-number-reviws mon-medium ml-2">(15 {{ texts.article.card.textReviews }})</p> -->
                    </div>
                    <p class="text-price-box mon-bold">{{ "$" +  (item.dUnitPrice + item.dFreightPerBox + item.dTotalInOutPerBox + item.sCustomPerBox).toFixed(2) + " USD FOB McAllen" }}</p>
                    <div class="display-flex align-items-center">
                      <div class="justify-content-flex-start width-50 mr-1">
                        <v-btn @click="addShoppingCart(item)" elevation="0" class="button-add mon-bold">
                          {{ texts.article.card.buttonAdd }}
                          <v-icon class="ml-1" size="16px">
                            mdi-cart-outline
                          </v-icon>
                        </v-btn>
                      </div>
                      <div class="justify-content-flex-end width-50 ml-1">
                        <v-btn :to="{ name: 'ViewArticle', params: { id: item.sProductId } }" elevation="0" class="button-view mon-bold">
                          {{ texts.article.card.buttonShow }}
                          <v-icon class="ml-1" size="16px">
                            mdi-arrow-right
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="display-flex align-items-center justify-content-center">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <div class="empty-content display-flex align-items-center justify-content-center">
              <div>
                <div class="display-flex justify-content-center">
                  <img width="170px" src="@/assets/icons/empty.gif" alt="proplat-image" />
                </div>
                <p class="text-empty mon-regular mb-9" v-html="texts.article.textEmptyContent" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "VendorDetailContentTabTwoLayout",
  data() {
    return {
      //VARIABLES
      aArticles: [],
      lengthPage: 0,
      iCurrentPage: 1,
      texts: "",
      loaderSkeleton: true,
    };
  },
  beforeMount() {
    this.texts = FILE.article[this.selectLanguage];
    this.getArticles();
  },
  methods: {
    getArticles: function() {
      this.loaderSkeleton = true;

      let headers = {};
      if (this.$store.state.sToken != "") {
        headers = {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        };
      }

      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers,
        params: {
          sCategoryId: this.sCategoryIdFilter,
          sSubcategoryId: this.sSubcategoryIdFilter,
          sSearch: this.sSearch,
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 12,
          sVendorEnterpriseId: this.$route.params.id,
          sBorderWarehouseId: "2a9a5c8f-5806-4d00-bc92-45a14c7974ad",
        },
      })
        .then((response) => {
          this.loaderSkeleton = false;
          this.aArticles = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    addShoppingCart: function(item) {
      if (this.$store.state.isLogged) {
        const payload = {
          sProductId: item.sProductId,
          dQuantity: item.dMinAmountAllowed,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(`${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`, payload, config)
          .then((response) => {
            this.mixSuccess(response.data.message);
            this.$store.commit("setFadeNotificationCart", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.mixError(this.texts.article.textNoSession);
      }
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    sSearch: function() {
      return this.$store.state.sSearch;
    },
    sFilter: function() {
      return this.$store.state.sFilter;
    },
    sCategoryIdFilter: function() {
      return this.$store.state.sCategoryIdFilter;
    },
    sSubcategoryIdFilter: function() {
      return this.$store.state.sSubcategoryIdFilter;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.article[this.selectLanguage];
        this.getArticles();
      }
    },
    sSearch: lodash.debounce(function(val) {
      this.iCurrentPage = 1;
      this.getArticles();
    }, 500),
    sCategoryIdFilter: function() {
      this.getArticles();
    },
    sSubcategoryIdFilter: function() {
      this.getArticles();
    },
    iCurrentPage: function() {
      this.getArticles();
    },
  },
};
</script>

<style scoped>
.button-view {
  background: transparent linear-gradient(250deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  height: 35px !important;
  font-size: 14px;
  width: 100%;
  text-transform: initial;
}

.button-add {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 35px !important;
  font-size: 14px;
  width: 100%;
  text-transform: initial;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 19px;
  opacity: 1;
  padding: 15px;
}

.content-image {
  width: 100%;
  height: 250px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

.text-title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 5px;
}

.text-provider {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
}

.text-location {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
}

.text-number-stars {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-number-reviws {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price-box {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.text-empty {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}

.empty-content {
  width: 100%;
  height: 350px;
}

.width-50 {
  width: 50%;
}
</style>
